<template>
    <v-dialog
        max-width="600px !important"
        overlay=false
        persistent
        scrollable
        transition="dialog-bottom-transition"
        v-model="open"
    >
        <v-card width="600px">
            <v-card-title class="success white--text" style="width: 600px">{{ $t('message.otherContractDocuments') + ": " + mainDocument }}</v-card-title>
            <v-card-text class="pa-3 overflow-y-auto">
                <v-simple-table class="appic-table-light specification-tables" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>{{ $t('message.source') }}</th>
                                <th>{{ $t('message.name') }}</th>
                                <th>{{ $t('message.uploaded') }}</th>
                                <th>{{ $t('message.actions') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="document in nonShippingDocuments">
                                <td>{{ document.Document.source }}</td>
                                <td>{{ document.Document.name }}</td>
                                <td>{{ formatDate(document.Document.uploaded) }}</td>
                                <td>
                                    <v-btn icon @click="viewFile(document.Document.location)">
                                        <v-icon class="pl-2" style="color: darkgray !important;" small>fas fa-eye</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <div class="text-center" v-if="nonShippingDocuments.length === 0">{{ $t('message.noDocumentsAvailable') }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-3"
                    color="default"
                    small
                    @click="open = false"
                >
                    {{ $t('message.close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {formatDate} from "Helpers/helpers";
import {mapFields} from "vuex-map-fields";
import {mapGetters} from "vuex";

export default {
    name: "NonShippingDocumentsList",
    props: {
        mainDocument: {
            type: String,
        },
        openDialog: {
            type: Boolean,
        },
        nonShippingDocuments: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        })
    },
    methods: {
        formatDate,
        viewFile (file) {
            let tab = window.open(file, '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {
        open (value) {
            if(value == false){
                this.$emit('dialog-closed')
            }
        },
        openDialog (value) {
            if(value){
                this.open = value
            }
        }
    }
}
</script>

<style scoped>
    .v-dialog {
        min-width: 300px !important;
        max-width: 400px !important;
    }
</style>